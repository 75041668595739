/*
axios 配置
 */
import axios from 'axios';
import router from '../router';
import {
    Loading, Message
} from 'element-ui';


const service = axios.create({
    baseURL: '/api',
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json',
    }
})

service.interceptors.request.use(
    config => {
        // let roleId = localStorage.getItem("roleId");
        let token = localStorage.getItem('token');
        // let token = "41381ca861284afab3a3002da97b75bf";
        if (token) config.headers.token = token;
        // if (roleId) config.headers.roleId = roleId;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

service.interceptors.response.use(

    response => {
        return Promise.resolve(response.data);
    },
    error => {
        if (error && error.response) {
            // 根据错误码跳转到对应的页面
            switch (error.response.status) {
                case 401:
                    router.push('/error/401')
                    break;
                // case 404:
                //     router.push('/error/404')
                //     break;
                // case 500:
                //     router.push('/error/500')
                //     break;
                case 403:
                    localStorage.clear();
                    // localStorage.removeItem("token");
                    // localStorage.removeItem("userInfo");
                    console.log("登录页")
                    console.log(window.location)
                    if (window.location.hash.indexOf('/login') == -1) {
                        router.push("/login");
                    } else {
                        Message({ message: '暂无用户权限！', type: 'warning' })
                    }

                    break;
                default:
                    return Promise.reject(error);
            }
        } else {
            return Promise.reject(error);
        }
        // let loading = this.$loading({ fullscreen: true })
        // loading.close();
    }
)

export default service;
