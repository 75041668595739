<!-- 返回顶部通用组件 -->
<template>
    <div class="realibox-back-top" v-show="show" @click="onScrollTopButtonClick($event)">
        <div class="com-to-top">
            <i class="el-icon-arrow-up"></i>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ReBackTop',
  props: [],
  data() {
    return {
      show:false,
      targetScrollEl: null  //触发滚动的对象
    };
  },
  mounted(){
  },
  methods: {
      //初始化组件，传入dom element表示监听元素滚动，不传表示监听window滚动
      init($el){
        console.log('backtop init')
        if(!$el){
          document.addEventListener('scroll', this.onWindowScroll);
        }else{
          this.targetScrollEl = $el;
          this.targetScrollEl.addEventListener('scroll', this.onTagetElementScroll);
        }
      },
      //销毁滚动监听事件，父组件销毁时调用
      destroy(){
        if(this.targetScrollEl){
          this.targetScrollEl.removeEventListener('scroll', this.onTagetElementScroll);
          this.targetScrollEl = null;
          this.show = false;
        }else{
          document.removeEventListener('scroll', this.onWindowScroll);
        }
      },
      //重新加载，更改滚动元素taget element
      refresh($targetEl){
        this.destroy()
        this.init($targetEl)
      },
      onScrollTopButtonClick (e){
        if(this.targetScrollEl){
          this.targetScrollEl.scroll({top: 0, behavior: 'smooth'})
        }else{
          window.scroll({top: 0, behavior: 'smooth'})
        }
      },
      onWindowScroll(){
        if(window.scrollY>200){
          this.show = true;
        }else{
          this.show = false;
        }
      },
      onTagetElementScroll(e){
        if(e.currentTarget.scrollTop>200){
          this.show = true;
        }else{
          this.show = false;
        }
      }
  },
};
</script>

<style lang="less" scoped>
  .realibox-back-top{
    position: fixed;
    bottom: 50px;
    right: 40px;
  }
</style>
