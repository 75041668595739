import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import http from './config/http';
import router from './router/index';
import store from "./store/index";
import dayjs from 'dayjs'
import lodash from './util/lodash'
import Loading from './components/Loading'
import ReBackTop from './components/ReBackTop'

// 瀑布流插件
import waterfall from 'vue-waterfall2'
Vue.use(waterfall)

// 全局引入回到顶部组件
import BackToTop from 'vue-backtotop';
Vue.use(BackToTop)

Vue.config.productionTip = false

Vue.use(ElementUI);
// 覆盖 Element UI Loading
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;

Vue.prototype.$http = http;

// 全局通用组件
import Empty from './components/Empty/index.vue';
Vue.component('Empty', Empty)
import HWaterfall from './components/HWaterfall'
Vue.component('HWaterfall', HWaterfall)
Vue.use(ReBackTop);   //返回顶部组件

Vue.prototype.$dayjs = dayjs
Vue.prototype.$lodash = lodash
//OBS配置设置为全局
Vue.prototype.$OBS_AK = "HDLCE3NWOH6EJJVGDF0X";
Vue.prototype.$OBS_SK = "555CgCGTf1e4rQl4Me3RwvPrSrI9IyS3oHJxgXZI";
Vue.prototype.$OBS_SERVER = "https://obs.cn-east-3.myhuaweicloud.com";
Vue.prototype.$OBS_BUCKET = "picmod";
Vue.prototype.$OBS_PREFIX = "https://picmod.obs.cn-east-3.myhuaweicloud.com/";

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
