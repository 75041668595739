export default {
    //工作台批量上传图片
    isBatchUploading: false, //是否在上传图片
    batchUploadDirecotry: "", //上传目录
    batchUploadTotal: 0, //上传文件总数
    batchUploadLimit: 1, //同时上传最大文件数量
    batchUploadCount: 0, //正在上传文件计数

    //工作台上传模型（单个）
    isUploadingModel: false, //是否在上传模型
    uploadModelDirectory: "", //模型上传目录

    //Sidetree当前菜单
    currentMenu: 0 //TODO: Sidetree中的current全部切换到store中
};
