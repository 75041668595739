export default {
  // 性别
  _SEXS: [{
    label: "男",
    value: 0
  }, {
    label: "女",
    value: 1
  }],
  // 是 / 否
  _IS: [{
    label: "是",
    value: 1
  }, {
    label: "否",
    value: 0
  }],

  _FORMAT: [
    { id: 1, name: "GIF" },
    { id: 2, name: "JPEG" },
    { id: 3, name: "PNG" },
  ],

  _JOB_STATUS: [
    { value: 0, label: "等待" },
    { value: 100, label: "任务开始" },
    { value: 120, label: "正在处理" },
    { value: 200, label: "成功" },
    { value: 300, label: "任务重试" },
    { value: 400, label: "任务失败" },
    { value: 500, label: "任务撤销、或不存在" },
  ],

  // 0成功状态码
  SUCCESS_CODE: 0,
  // 200成功状态码
  SUCCESS_CODE_200: 200,

}
