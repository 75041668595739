<template>
  <el-empty v-bind="$props" :image-size="360">
    <div slot="image">
      <img src="../../assets/images/no-content/common.png" />
    </div>
  </el-empty>
</template>

<script>
export default {
  name: "Empty"
};
</script>
