const state = {
    //collectionList页面的cids查询参数
    collectionList: { cids: "" }
};

const getters = {};

const mutations = {
    setCollectionListCids(state, cids) {
        if (cids !== undefined && cids !== null && cids !== "") {
            if (cids === "reset") {
                state.collectionList.cids = "";
            } else {
                state.collectionList.cids = cids;
            }
        }
    },
    resetCollectionList(state) {
        state.collectionList = { cisd: "" };
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations
};
