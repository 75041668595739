import http from '@/config/http.js';
import staticDic from './staticDic.js';

const state = {
  ...staticDic,
  _FORMAT: [],
  _FOLDER_LIST: [],
}

const getters = {
  getDic(state) {
    return function (type, value) {
      return state[type] ? state[type].reduce((pre, cur) => {
        return cur.value == value ? pre.concat(cur.label) : pre
      }, "") : null
    }
  },
}

const mutations = {
  initDic(state) { },
  updateFormat(state, data) {
    state._FORMAT = data;
  },
  updateFolder(state, data) {
    state._FOLDER_LIST = data;
  }
}

const actions = {
  // 获取图片类型列表
  getFormatList({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      http.get("/dict-format/list").then(res => {
        let formatList = [];
        if (res.code == state.SUCCESS_CODE) {
          formatList = res.data.map(item => {
            return {
              name: item.name,
              id: item.id
            }
          })
          commit('updateFormat', formatList);
        }
        resolve(res.code);
      }).catch(err => {
        reject(err)
      })
    })
  },
  // 获取文件夹列表
  getFolderList({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      http.get("/directory-info/nodes", { params }).then(res => {
        if (res.code == state.SUCCESS_CODE) {
          let data = res.data;
          commit('updateFolder', data);
        }
        resolve(res.code);
      }).catch(err => {
        reject(err)
      })
    })
  },
}



export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
