import Vue from 'vue'
import VueRouter from 'vue-router';
import staticRoutes from './staticRoutes';
import whiteList from "./whiteList";
import store from '../store';
import http from '../config/http';

import {
    Loading
} from 'element-ui';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'hash',
    routes: staticRoutes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};

router.beforeEach((to, from, next) => {
    // 登录url+token的情况，直接使用query参数的token
    if(to.path==='/login' && to.query.token){
        let token = to.query.token;
        localStorage.setItem("token", token);
        http.get('/sys-user/load').then(res=>{
            if(res){
                localStorage.setItem("userInfo", JSON.stringify(res.data));
                localStorage.setItem(
                  "nickname",
                  res.data.nickname ? res.data.nickname : res.data.username
                );
                next({
                    path: '/home',
                    replace: true
                });
            }else{
                localStorage.removeItem('token')
                next({
                    path: '/login',
                    replace: true
                });
            }
        })
    }else{
        // 获取localstorage里的token
        let token = localStorage.getItem('token');

        // 若已登录，用户直接通过地址栏前往登录页，则不让前往
        // 如果为白名单，直接访问
        if (whiteList.includes(to.path)) {
            // 若已登录，用户直接通过地址栏前往登录页，则不让前往
            if (token && to.path === '/login') {
                next({
                    path: '/home',
                    replace: true
                });
            } else {
                return next();
            }
        }
        if (token) {
            if (to.path === "/login") {
                next({
                    path: '/home',
                    replace: true
                });
            }
            next();
        } else {
            if (to.path === "/login") {
                next();
            } else {
                next({
                    path: '/login',
                    replace: true
                });
            }
        }
        next()
        // init(to, from, next)
    }
});

async function init(to, from, next) {
    if (store.state.dic._FORMAT.length == 0) {
        var p1 = store.dispatch("dic/getFormatList");
        Promise.all([p1]).then(values => {
            next();
        })
    }
    next();
}

export default router;
