// 正则柯里化
const checkByRegExp = (regExp) => (str) => regExp.test(str)
// 校验手机
const checkPhone = checkByRegExp(/^1\d{10}$/)

/**
 * 手机号码脱敏
 * @param number
 */
const phoneNuberConvert = (str) => {
  if (!str) return "";
  if (!checkPhone(str)) return str

  let pat = /(\d{3})\d*(\d{4})/;
  let result = str.replace(pat, "$1****$2");
  return result;
}

export default {
  checkByRegExp,
  checkPhone,
  phoneNuberConvert
}
