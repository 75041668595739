const staticRoutes = [
    {
        path: '/',
        redirect: '/login'
    },
    // 登录
    {
        path: '/login',
        component: () => import('../page/login')
    },
    // 注册
    {
        path: '/register',
        component: () => import('../page/register')
    },
    // 忘记密码
    {
        path: '/resetPwd',
        component: () => import('../page/resetPwd')
    },
    {
        path: '/error',
        component: () => import('../page/error'),
        children: [
            {
                path: '401',
                component: () => import('../page/error/401')
            },
            {
                path: '404',
                component: () => import('../page/error/404')
            },
            {
                path: '500',
                component: () => import('../page/error/500')
            }
        ]
    },

    // 首页
    {
        path: '/home',
        component: () => import('../page/home')
    },
    // 首页图片详情页
    {
        path: '/home/imageDetail/:id',
        component: () => import('../page/home/imageDetail.vue')
    },
    // 分享页
    {
        path: '/share',
        component: () => import('../page/share')
    },

    // 社区
    {
        path: '/community',
        redirect: '/community/collectionList',
        component: () => import('../page/community'),
        children: [
            // 社区首页(已不需要，注释)
            // {
            //     path: '/community/home',
            //     component: () => import('../page/community/home'),
            // },
            // 作品集页面
            {
                path: '/community/collectionList',
                component: () => import('../page/community/collectionList'),
            },
            // 某个图集页面
            {
                path: '/community/collection/:id',
                component: () => import('../page/community/collection'),
            },
            // 作品列表
            {
                path: '/community/workList',
                component: () => import('../page/community/workList'),
            },
            // 设计师首页
            {
                path: '/community/designer/:id',
                component: () => import('../page/community/designer'),
            },
            // 设计师列表
            {
                path: '/community/designerList',
                component: () => import('../page/community/designerList'),
            },
            // 图片详情页
            {
                path: '/community/imageDetail/:id',
                component: () => import('../page/community/imageDetail'),
            },
        ],
    },

    // 我是设计师
    {
        path: '/designer',
        component: () => import('../page/designerHome'),
        redirect: '/designer/home',
        children: [
            {
                path: '/designer/home',
                redirect: '/designer/home/concern',
                component: () => import('../page/designerHome/home/index'),
                children: [
                    // 我的关注
                    {
                        path: '/designer/home/concern',
                        component: () => import('../page/designerHome/home/concern'),
                    },
                    // 我的粉丝
                    {
                        path: '/designer/home/fans',
                        component: () => import('../page/designerHome/home/fans'),
                    },
                    // 我的积分
                    {
                        path: '/designer/home/integral',
                        component: () => import('../page/designerHome/home/integral'),
                    },
                    // 我的下载
                    {
                        path: '/designer/home/likes',
                        component: () => import('../page/designerHome/home/likes'),
                    },
                    // 我的点赞
                    {
                        path: '/designer/home/download',
                        component: () => import('../page/designerHome/home/download'),
                    },
                    // 我的点赞
                    {
                        path: '/designer/home/points',
                        component: () => import('../page/designerHome/home/points'),
                    },
                ]
            },
            {
                path: '/designer/setting',
                redirect: '/designer/setting/base',
                component: () => import('../page/designerHome/setting/index'),
                children: [
                    // 基本信息
                    {
                        path: '/designer/setting/base',
                        component: () => import('../page/designerHome/setting/base'),
                    },
                    // 修改密码
                    {
                        path: '/designer/setting/modifyPwd',
                        component: () => import('../page/designerHome/setting/modifyPwd'),
                    },
                    // 绑定手机号
                    {
                        path: '/designer/setting/phone',
                        component: () => import('../page/designerHome/setting/phone'),
                    },
                    // 绑定微信号
                    {
                        path: '/designer/setting/wxcode',
                        component: () => import('../page/designerHome/setting/wxcode'),
                    },
                ],
            }
        ],
    },

    // 管理员后台
    {
        path: '/manager',
        redirect: '/manager/works',
        component: () => import('../page/manager'),
        children: [
            // 成员管理
            {
                path: '/manager/member',
                redirect: '/manager/member/designer',
                component: () => import('../page/manager/member/index'),
                children: [
                    // 成员管理--设计师
                    {
                        path: '/manager/member/designer',
                        component: () => import('../page/manager/member/designer'),
                    },
                    // 成员管理--黑名单
                    {
                        path: '/manager/member/blacklist',
                        component: () => import('../page/manager/member/blacklist'),
                    },
                ]
            },
            // 作品管理
            {
                path: '/manager/works',
                redirect: '/manager/works/collection',
                component: () => import('../page/manager/works/index'),
                children: [
                    // 公开作品集--图集列表
                    {
                        path: '/manager/works/collection',
                        component: () => import('../page/manager/works/collection/index'),
                    },
                    {
                        path: '/manager/works/collection/detail/:id',
                        component: () => import('../page/manager/works/collection/detail'),
                    },
                    // 作品集分类
                    {
                        path: '/manager/works/category',
                        component: () => import('../page/manager/works/category'),
                    },
                    // 下架作品
                    {
                        path: '/manager/works/offShelf',
                        component: () => import('../page/manager/works/offShelf'),
                    },
                ]
            },
            // 标签管理
            {
                path: '/manager/label',
                redirect: '/manager/label/category',
                component: () => import('../page/manager/label/index'),
                children: [
                    // 分类管理
                    {
                        path: '/manager/label/category',
                        component: () => import('../page/manager/label/category'),
                    },
                    // 标签列表
                    {
                        path: '/manager/label/list',
                        component: () => import('../page/manager/label/list'),
                    },
                ]
            },
            // 积分管理
            {
                path: '/manager/integral',
                component: () => import('../page/manager/integral'),
            },
        ],
    },
    // 模型预览
    {
        // path: '/model/view/:id/:sceneId',
        path: '/model/view/:id',
        component: () => import('../page/model/view'),
    },
    {
        path: '/model/viewer/:id',
        component: () => import('../page/model/viewer'),
    },
    {
        path: '/test',
        component: () => import('../page/test/index'),
    },
];

export default staticRoutes;
