export default {
  //排队批量上传图片，同时计数
  batchUpload(state){
    if (!state.isBatchUploading) {
      state.isBatchUploading = true;
    }
    ++state.batchUploadTotal;
  },

  //执行上传图片，同时计数
  startUploadImage(state) {
    if (!state.isBatchUploading) {
      state.isBatchUploading = true;
    }
    ++state.batchUploadCount;
  },

  //设置图片上传目录
  setBatchUploadDirecotry(state, directoryId) {
    if (directoryId && !state.isBatchUploading) {
      state.batchUploadDirecotry = directoryId;
    }
  },

  //上传图片结束（obs上传成功或失败时都结束）
  closeUploadImage(state) {
    if (state.isBatchUploading) {
      --state.batchUploadCount;
      --state.batchUploadTotal;
      console.log("batch upload ", state.batchUploadTotal);
      if (state.batchUploadTotal == 0) {
        state.isBatchUploading = false;
        state.batchUploadDirecotry = "";
        console.log("batch upload finish");
      }
    }
  },

  //指定模型上传目录
  setUploadModelDirecotry(state, directoryId) {
    if (directoryId && !state.isModelUploading) {
      state.uploadModelDirectory = directoryId;
    }
  },

  //开始上传模型
  startUploadModel(state){
    state.isUploadingModel = true;
  },

  //结束上传模型
  closeUploadModel(state){
    state.isUploadingModel = false;
    state.uploadModelDirectory = '';
  },

  setCurrentMenu(state, current){
    state.currentMenu = current;
  }

};
