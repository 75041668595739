import http from '../../config/http';
import { Message } from 'element-ui';
const state = {
	initCollection: false,
	data: [], // 当前已加载作品集数据
	currentPreviewId: null, // 当前预览id
	fetchLoading: false, // 加载loading
	pageable: {
		// 分页配置
		directoryId: null,
		pageSize: 100000,//获取作品集所有作品
		pageNum: 1,
		menuSelect: 2,
		total: 0,
		currentPage: 0,
	},
};
//  1 1 11 1
const mutations = {
	// 设置作品集
	setCollection(state, data) {
		state.data = data;
	},
	// 添加数据到作品集
	addCollection(state, data) {
		state.data.push(...data);
	},
	/**
	 * 作品更改（移出）作品集，当前预览项改为上一个非模型作品,
	 * 负载传入的回调函数会传入下一个预览项的id，id为-1时说明没有下一个预览项
	 * @param {*} state
	 * @param {{id:number,callback:(nextId:number)=>void}} payload
	 */
	shiftCollection(state, payload) {
		const { id, callback } = payload;
		const idx = state.data.findIndex((item) => item.id === id);
		const dataSlice = state.data.slice(0, idx).filter(item=>item.type===0);
		console.log('slice:', dataSlice);
		let nextPreviewItemIdx = idx - 1;
		let nextId = -1;
		// 当前已加载作品集已无上一项
		if (dataSlice.length !== 0) {
			while (
				state.data[nextPreviewItemIdx].type !== 0 &&
				nextPreviewItemIdx !== -1
			) {
				nextPreviewItemIdx -= 1;
			}
			if (nextPreviewItemIdx !== -1) {
				nextId = state.data[nextPreviewItemIdx].id;
			}
		} else {
			const nextItem = state.data.filter(
				(item) => item.type === 0 && item.id !== id
			)[0];
			if (nextItem) {
				nextId = nextItem.id;
			}
		}
		if (nextId === -1) {
			state.currentPreviewId = null;
			state.data = [];
			callback(nextId);
			return;
		}
		state.currentPreviewId = nextId;
		state.data = state.data.filter((item) => item.id !== id);
		callback(nextId);
	},
	// 设置当前预览项id
	setCurrentPreviewId(state, id) {
		state.currentPreviewId = id;
	},
	initCollection(state) {
		state.initCollection = true;
	},
};

const getters = {
	// 当前预览项是否为已加载作品集的最后一项
	previewIsLatestItem(state) {
		return state.data[state.data.length - 1].id == state.currentPreviewId;
	},
};

const actions = {
	/**
	 * 获取作品集数据
	 * @param {number} directoryId 作品集id
	 */
	async initCollectionData({ state, commit }, directoryId) {
		if (state.data.length !== 0) return;
		state.pageable.directoryId = directoryId;
		state.fetchLoading = true;
		console.log('init collection ata');
		const param = {
			...state.pageable,
			directoryId,
		};
		try {
			const ret = await http.post(`/resource-info/listPage`, param);
			commit('initCollection');
			commit('setCollection', ret.data.records);
		} catch (err) {
			console.log('获取工作集信息失败：', err);
		} finally {
			state.fetchLoading = false;
		}
	},

	async loadMoreCollectionData({ state, commit }) {
		state.pageable.pageNum += 1;
		state.fetchLoading = true;
		const param = {
			...state.pageable,
		};
		try {
			const ret = await http.post(`/resource-info/listPage`, param);
			if (ret.data.records.length === 0) {
				Message.warning('没有更多数据');
				return;
			}
			commit('addCollection', ret.data.records);
		} catch (err) {
			console.log('获取工作集信息失败：', err);
		} finally {
			state.fetchLoading = false;
		}
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
};
