// const state = {
//     username: "stevepomp",
//     roleType:'a'
// };

// const actions = {};

// const mutations = {
//     toggleRoleType(state,role){
//         console.log(role)
//         state.roleType = role
//     }
// };

// export default {
//     state,
//     actions,
//     mutations
// }

const state = {
    // 用户名
    name: ''
};

const mutations = {
    setName: (state, data) => {
        if (data) {
            localStorage.setItem('userName', encodeURIComponent(data));
        } else {
            localStorage.removeItem('userName');
        }
        state.name = data
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
