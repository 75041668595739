import auth from './auth';
import user from './user';
import dic from "./dic/index";
import community from './community';
import collectionSidebar from "./collectionSidebar"

const modules = {
    dic,
    auth,
    user,
    community,
    collectionSidebar,
}

export default modules;
