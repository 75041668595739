<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    // 解决地址栏路由更新，但是页面未刷新问题
    window.addEventListener(
      "hashchange",
      () => {
        let currentPath = window.location.hash.slice(1);
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath);
        }
      },
      false
    );
    document.addEventListener(
      "drop",
      function (e) {
        e.preventDefault();
      },
      false
    );
    document.addEventListener(
      "dragover",
      function (e) {
        e.preventDefault();
      },
      false
    );
  },
};
</script>

<style lang="less">
@import url("./assets/style/index.less");
</style>
